<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="manageColor"
            v-model="manageMsj")
                | {{ manageText }}
                v-btn(flat dark @click.native="manageMsj = false") {{ $t('cerrar') }}
        v-dialog(v-if="modal" v-model="modal" max-width="600" persistent)
            v-form
                v-card
                    v-card-text(v-if="preload")
                       | {{ $t('Cargando...') }}
                    template(v-else)
                        v-card-title
                            .btns.btns-right
                                v-btn(icon color="secondary" small @click="close")
                                    v-icon close
                            | {{ $t('Usuario') }}
                        v-card-text
                            v-layout(row wrap)
                                v-flex(xs12)
                                    v-text-field.required(:label="$t('Nombres')" v-model="model.names" required)
                                v-flex(xs12)
                                    v-text-field.required(:label="$t('Primer apellido')" v-model="model.surname" required)
                                v-flex(xs12)
                                    v-text-field(:label="$t('Segundo apellido')" v-model="model.surname2")
                                v-flex(xs12)
                                    v-text-field.required(:label="$t('Correo electrónico')" v-model="model.email" required)
                                v-flex(xs12)
                                    v-text-field(
                                        type="password" 
                                        :label="model.hasOwnProperty('uuid') ? $t('Cambiar contraseña') : $t('Contraseña')" 
                                        v-model="model.password"
                                        :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                        :append-icon-cb="() => (e1 = !e1)"
                                        :type="e1 ? 'password' : 'text'"
                                        )
                                v-flex(xs12)
                                    v-select.required(
                                        v-model="model.type"
                                        :items='genericProfiles'
                                        item-text="name"
                                        item-value="uuid"
                                        label='Perfil del usuario'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                    )
                                //- Solo callcenter
                                v-flex(xs12 v-if="model.type == 3")
                                    v-select.required(
                                        v-model="model.networks"
                                        :items='networks'
                                        item-text="name"
                                        item-value="uuid"
                                        label='Redes asociadas'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                        multiple
                                    )
                                v-flex.mt-2(xs12)
                                    label {{$t('Habilitado')}}
                                    v-divider.mt-2
                                    .switch-group
                                        label {{$t('No')}}
                                        v-switch(:label="$t('Si')" color="success" v-model="model.status")
                                    br
                        v-card-actions
                            .btns.btns-right
                                v-btn(color="secondary" small @click="close") {{ $t('Cancelar') }}
                                v-btn(:loading="loadingBtn" color="primary" large @click.stop="save") {{ $t('Guardar') }}
</template>

<script>

import _m from 'lodash/merge'
import fn from 'mixins/fn'

export default {
    mixins: [fn],
    data(){
        return {

            // Vista
            modal     : false,
            loadingBtn: false,
            preload   : false,
            e1        : true,

            // Mensajes
            manageMsj  : false,
            manageColor: '',
            manageText : '',

            // Datos
            modelSchema: {
                names    : '',
                type     : '',
                email    : '',
                status   : 1,
                networks : [],
                password : ''
            },
            model: {},
            networks: []
        }
    },
    methods: {
        async open(data = this.modelSchema){

            this.modal = true
            this.preload = true

            await this.fetchNetworks()

            this.model = _m({}, data)
            this.preload = false
        },
        close(){
            this.model      = _m({}, this.modelSchema)
            this.loadingBtn = false
            this.modal      = false
        },
        fetchNetworks(){
            return new Promise((resolve, reject) => {

                this.$api(this, (xhr) => {
                    xhr.get('/network', {
                        params: {
                            list: 1
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networks = data.data
                        }
                        resolve()

                    }).catch(() => {
                        this.networks = []
                        this.preload  = false
                        resolve()
                    })
                })
            })
        },
        save(){

            this.loadingBtn = true
            
            let params      = _m({}, this.model)
            params.status   = params.status ? 1 : 0
            params.networks = JSON.stringify(params.networks)

            this.$api(this, (xhr) => {
                xhr.post('/user', this.$qs.stringify(params)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.manageMsj  = true

                    if(data.response){

                        this.manageColor = 'success'
                        this.manageText  = data.message
                        this.manageModal = false

                        this.modal = false
                        this.$emit('success')

                    }else{
                        this.manageColor = 'error'
                        this.manageText = data.message
                    }

                }).catch(() => {
                    this.loadingBtn = false
                    this.catchError('manage')
                })
            })
        }
    }
}

</script>
