<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-tooltip(left)
                    template(slot="activator")
                        v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                            v-icon search
                    span {{ $t('Filtrar usuarios') }}
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-divider.mb-4.grey
                        span.white--text {{ $t('Estado') }}
                        v-checkbox(dark v-model="filter.status" :value="1" :label="$t('Activo')")
                        v-checkbox(dark v-model="filter.status" :value="0" :label="$t('Inactivo')")
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                    :timeout="3000",
                    :top="true"
                    :right="true"
                    :color="userColor"
                    v-model="userMsj")
                    | {{ userText }}
                    v-btn(flat dark @click="userMsj = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Usuarios')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-data-table(
                            :headers='headers'
                            :items='users'
                            :pagination.sync='pagination'
                            :rows-per-page-items="$root.rowsPageItems"
                            :rows-per-page-text="$root.rowsPageText" 
                            item-key='uuid'
                            :total-items="total"
                            :loading="loading"
                        )
                            template(slot="no-results")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot="no-data")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                            template(slot='items' slot-scope='props')
                                tr
                                    td {{ props.item.name }}
                                    td {{ profileLabel(props.item.type) }}
                                    td {{ props.item.email }}
                                    td {{ $t(props.item.status == 1 ? 'Activo' : 'Inactivo') }}
                                    td.text-xs-right
                                        v-tooltip(top)
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click.stop="$refs.manage.open(props.item)") edit
                                            span {{$t('Editar')}}
                    v-tooltip(left)
                        template(slot="activator")
                            v-btn(@click.native="$refs.manage.open()" fixed dark fab bottom right color='primary')
                                v-icon add
                        span {{ $t('Agregar usuario') }}
        manage(ref="manage" @success="fetchUser")
</template>

<script>

    import auth from 'mixins/auth'
    import _ from 'lodash/debounce'
    import _m from 'lodash/merge'

    import Manage from 'components/user/Manage'

    export default {
        components: {
            Manage
        },
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Usuarios') + ' - Wappid'
            }
        },
        data(){
            return {

                // Loader
                preload   : false,
                pagination: {
                    page: 1,
                    rowsPerPage: 20
                },
                total     : 0,
                loading   : false,
                cancelFilter: null,
                isFilter  : false,

                // Filtros
                drawerRight   : false,
                modalDateStart: false,
                modalDateEnd  : false,
                filter        : {},
                filterSchema  : {
                    query     : '',
                    status    : []
                },

                // Listas
                users         : [],
                loadingBtn    : false,
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'names',
                        sortable: false
                    },
                    {
                        text: 'Perfil',
                        align: 'left',
                        value: 'profile',
                        sortable: false
                    },
                    {
                        text: 'Correo electrónico',
                        align: 'left',
                        value: 'email',
                        sortable: false
                    },
                    {
                        text: 'Estado',
                        align: 'left',
                        value: 'status',
                        sortable: false
                    },
                    {
                        text: 'Opciones',
                        align: 'right',
                        value: 'options',
                        sortable: false
                    }
                ],

                // Mensajes
                userMsj  : false,
                userColor: '',
                userText : '',
            }
        },
        watch: {
            filter: {
                handler(){

                    this.preload = true
                    this.total = 0
                    this.users = []

                    this.fetchUser(true)
                },
                deep: true
            },
            pagination: {
                handler () {
                    if(!this.isFilter){
                        this.fetchUser()
                    }
                },
                deep: true
            }
        },
        created(){
            this.filter = _m({}, this.filterSchema)
        },
        methods: {

            fetchUser: _(function(isFilter = false){

                this.isFilter = isFilter

                if(this.cancelFilter != null){
                    this.cancelFilter.cancel()
                }

                let CancelToken   = this.$axios.CancelToken
                this.cancelFilter = CancelToken.source()

                this.loading = true

                let url    = '/user'
                let filter = this.filter
                let params = {
                    limit  : this.pagination.rowsPerPage,
                    offset : (this.pagination.page - 1) * this.pagination.rowsPerPage,
                    filter : JSON.stringify(filter)
                }

                this.$api(this, (xhr) => {
                    xhr.get(url, { params: params }).then((r) => {

                        let data = r.data
                        this.preload = false
                        this.loading = false
                        
                        if(data.response){
                            
                            this.users = data.data
                            this.total = data.total

                        }else{
                            this.users = []
                        }

                        this.$nextTick(() => {
                            this.isFilter = false
                        })
                        

                    }).catch(() => {
                        
                        this.preload   = false
                        this.loading   = false
                        this.isFilter  = false

                        this.catchError('user')
                    })
                })

            }, 500)
        }
    }
</script>